<script>
import Layout from "../../layouts/main";
import {
  examsMethods,
  classesexamsMethods,
  studentClassMethods,
} from "@/state/helpers";


export default {
  components: {
    Layout,
  },
  data() {
    return {
      Student:null,
      ExamsList:[],
      SubExamsList:[]
    };
  },
  created(){
     this.Student = JSON.parse(localStorage.getItem("user"));
    this.getStudentClass({ id:this.Student.id})
    this.getExam({ id:this.Student.schoolId})
    this.getClassesExam({ id:this.Student.schoolId});
  },
  mounted(){
    this.InitData();
  },
  computed: {
    classesexams() {     
      return this.$store ? this.$store.state.classesexams.ClassesExams : null;
    },
    exams() {

      return this.$store ? this.$store.state.exam.Exams : null;
    },
    studentsclass() {
      return this.$store ? this.$store.state.studentsclass.StudentsClass : null;
    },
  },
  methods:{
    ...studentClassMethods,
    ...examsMethods,
    ...classesexamsMethods,
    InitData(){     
      this.ExamsList = []
      let subexam = this.classesexams.filter(x=> this.checkClasses(x.classeid) )
      this.exams.forEach(el=>{
        let subsub = subexam.filter(x=> x.examid == el.id)
        if(subsub.length>0){
          this.ExamsList.push(el)
        }
      })
    },
    GetSubExams(val){
      return this.classesexams.filter(x=> x.examid == val )
    },
    checkClasses(val) {
      let sub = this.studentsclass.filter(x=>x.classid == val)
      if(sub.length>0){
        return true
      }else{
        return false
      }
    },
    newMeth(){}
  }
};
</script>

<template>
  <layout>
    <b-col>
      <b-row v-for="exam in ExamsList" :key="exam.id">
        <b-col>
          <b-card>
            <b-col>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <h5> <b>{{exam.title}}</b></h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5>{{exam.description}}</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5>{{exam.subject}}</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h6>{{exam.date}} | {{exam.startat}} - {{ exam.endat }}</h6>
                    </b-col>
                  </b-row>
                  <b-row >
                    <b-col v-for="examgroup in  GetSubExams(exam.id)" :key="examgroup.id" class="mt-3">
                      <div style="background-color:darkred;padding:10px;color:white; border-radius:10px;">
                      <h6 style="color:white;">{{examgroup.classename}} | {{examgroup.room}}</h6>

                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <h5>{{exam.schooltitle}}</h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
  
    </b-col>
  </layout>
</template>
